export default {
	message: {
	  approve: "授權",
	  renounceOwnership: "放棄權限",
	  transfer: "轉帳",
	  transferFrom: "從...轉帳",
	  transferOwnership: "轉讓所有權",
	  amount: "數量",
	  spender: "支出者",
	  sender: "發送者",
	  recipient: "接收者",
	  newOwner: "新所有者",
	  write: "執行",
	  addToBlacklist: "添加黑名單",
	  disableTransfers: "關閉交易",
	  enableTransfers: "開啟交易",
	  removeFromBlacklist: "移除白名單",
	  account: "地址",
	  buy: "買入",
	  sell: "賣出",
	  setpar: "設置LP地址",
	  setTeam: "行銷費率",
	  setpool: "設置加池比例",
	  setDividends: "設置分紅比例",
	  setBurn: "設置銷毀比例",
	  amountOu: "數量",
	  Dividendcoins: "持有什麼幣分紅",
	  setDividendcoins: "代幣地址",
	  setDividendswitch: "分紅開關",
	  enable: "開關",
	  Factory: "工廠合約",
	  PancakeRouter: "路由地址",
	  Teamaddress: "團隊地址",
	  par: "交易對地址",
	  setFactory: "設置工廠合約",
	  setMinimum: "最小持倉",
	  setPancakeRouter: "設置路由地址",
	  setTeamaddress: "設置團隊地址",
	  updateBlacklist: "設置黑名單",
	  isBlacklisted: "開關",
	  updateTransfers: "交易開關",
	  swapTokensAtAmount: "最小賣出金額",
	  setswapTokensAtAmount: "設置最小賣出金額",
	  setRewardaddress: "設置分紅比地址",
	  Rewardaddress: "地址",
	  submit: "提交",
	  enterContractAddress: "請輸入合約地址",
	  pleaseEnterValidContractAddress: "請輸入有效的合約地址",
	  transactionSuccessful: "交易成功",
	  contractNotOpenSourceOrInvalidAddressOrWrongChain: "合約未開源，或者合約地址錯誤，或者鏈錯誤"
	},
	dex: {
	  swap: "交易",
	  addliquidity: "添加流動性",
	  removeliquidity: "刪除流動性",
	  Balance: "餘額:",
	  ConnectWallet: "連結錢包",
	  PleaseEnterAmount: "請輸入兌換數量",
	  InsufficientLiquidity: "流動性不足",
	  InsufficientBalance: "餘額不足",
	  Authorization: "授權",
	  Transaction: "交易",
	  PleaseEnterSwapAmount: "請輸入兌換數量",
	  networkError: "網絡錯誤",
	  transactionSuccessful: "交易成功",
	  PleaseEnterAddAmount: "請輸入添加數量",
	  PleaseEnterRemoveAmount: "請輸入刪除數量",
	  PairDoesNotExist: "交易對不存在",
	  PleaseEnterTokenAddressOrName: "請輸入代幣地址或名稱",
	  CommonTokens: "常用代幣",
	  AddedByUser: "由用戶添加",
	  InvalidTokenAddressLength: "代幣地址長度錯誤",
	  SlippageLimit: "滑點上限",
	  ExpertMode: "專家模式",
	  BypassConfirmationAndAllowHighSlippage: "繞過確認模式並允許高滑點交易。使用風險自負。",
	  selecttoken: "選擇代幣",
	  settings: "設置"
	},
	mint: {
	  CreateToken: "創建Token",
	  TokenManagement: "Token管理",
	  name: "名稱:",
	  TokenName: "代幣名稱",
	  Symbol: "符號:",
	  TokenSymbol: "代幣符號",
	  Quantity: "數量:",
	  TotalSupply: "發行數量",
	  Decimals: "精度:",
	  TradeSwitch: "交易開關:",
	  Blacklist: "黑名單:",
	  HoldersDividend: "持幣分紅:",
	  PoolDividend: "加池分紅:",
	  DividendContract: "分紅合約:",
	  MinHold: "最小持倉:",
	  DividendToken: "分紅本幣:",
	  MarketingAddress: "行銷地址:",
	  buy: "買入:",
	  pool: "流動性:",
	  marketing: "行銷:",
	  dividend: "分紅:",
	  Burn: "銷毀:",
	  sell: "賣出",
	  SelectPoolContract: "請選擇加池合約",
	  ConnectWallet: "連結錢包",
	  address: "合約地址",
	  deploySuccess: "部署成功",
	  copy: "複製",
	  copySuccess: "複製成功",
	  networkNotSupported: "暫不支持此網絡",
	  moreSettings: "更多設置",
	  enterContractAddress: "請輸入合約地址",
	  manage: "管理"
	},
	Mixezs: {
	  deposit: "存款",
	  Cheque: "憑證",
	  withdraw: "取款",
	  token: "代幣",
	  selectToken: "請選擇代幣",
	  Amount: "數量",
	  selectAmount: "請選擇數量",
	  ConnectWallet: "連結錢包",
	  Authorization: "授權",
	  order: "訂單",
	  selectDepositOrder: "請選擇存款訂單",
	  recipient: "取款人",
	  enterRecipientAddress: "請輸入收款人地址",
	  generateVoucher: "生成憑證",
	  Proof: "憑證",
	  pleaseEnterProof: "請輸入憑證",
	  prooferror: "憑證錯誤或者您不是接收者，請重新輸入",
	  withdrawsuccess: "取款成功",
	  depositsuccess: "存款成功",
	  withdrawproof: "取款憑證",
	  titleone: "Mixezs合約已通過",
	  titletwo: "審計!",
	  titlethree: "查看審計報告!",
	  MixezsPrivacyTransfer: "Mixezs 隱私轉帳介紹",
	  MixezsIntro: "Mixezs 是一款專注於隱私保護的區塊鏈轉帳工具，結合了現代密碼學技術，保障用戶的交易隱私，確保每筆轉帳都無法被外界追蹤。Mixezs 的系統由兩個核心智能合約組成：存款合約 (Mixer) 和 驗證合約 (Verifier)。",
	  CoreHighlights: "核心亮點",
	  SeamlessPrivacyProtection: "無縫隱私保護：",
	  EffectiveTrackingPrevention: "通過統一的存款金額設計，Mixezs 有效阻止外部對存款來源的識別和追蹤。即便外界能夠觀察到提款記錄，也無法借此逆推出資金的存入者身份。",
	  DeepAnonymityVerification: "深度匿名性驗證：",
	  AdvancedSignatureVerification: "系統使用高級的簽名驗證算法，並結合不可預測的隨機數生成技術，全麵保護用戶隱私。用戶身份、交易細節都經過加密和混淆處理，使得任何人無法獲取具體的存款和轉帳信息。",
	  HighlySecureVerificationLogic: "高度安全的驗證邏輯：",
	  VerifierContractDesign: "Verifier 合約的核心算法設計，不僅為系統提供了額外的安全防線，也確保了驗證過程的完整性。通過內建的密碼學驗證和防護機制，有效杜絕了潛在的攻擊與漏洞利用。",
	  AuditLevelSecurity: "審計級別的安全性：",
	  ThirdPartyAudit: "在上線之前，所有合約均通過了第三方安全機構的嚴密審計。用戶可以完全放心其資金的安全性，任何交易都在隱私不被泄露的前提下進行。",
	  ContractFunctions: "合約功能",
	  DepositContract: "存款合約 (Mixer)：",
	  DepositMechanism: "確保所有用戶以相同金額存款，通過資金混合機制打破原始資金的流動軌跡，最大限度保護資金來源隱私。",
	  VerificationContract: "驗證合約 (Verifier)：",
	  SignatureVerification: "實現高效的簽名驗證，基於尖端的密碼學機制，合約在確保隱私的同時，也防止了重放攻擊和簽名偽造。",
	  MixezsAdvantages: "Mixezs 的優勢",
	  TopPrivacyProtection: "頂級隱私防護：",
	  InnovativeDesign: "通過創新的合約設計與密碼學技術，Mixezs 徹底保障了用戶隱私，外界無法通過鏈上數據進行溯源。",
	  SecurityAndDecentralization: "安全性與去中心化：",
	  RigorousAudit: "經過嚴格審計的智能合約運行在去中心化區塊鏈上，保證交易安全。",
	  AntiAttackDesign: "防攻擊設計：",
	  CryptographicMechanisms: "通過尖端的密碼學機制，系統保證了每筆交易的唯一性，徹底防止預測性攻擊。"
	},
	nav: {
		swap: '交換',
		mint: '鑄造',
		Mixezs: '混幣器'
	}

  }
