export default {
    message: {
        approve: "Aprobar",
        renounceOwnership: "Renunciar a la propiedad",
        transfer: "Transferir",
        transferFrom: "Transferir desde...",
        transferOwnership: "Transferir la propiedad",
        amount: "Cantidad",
        spender: "Gasto",
        sender: "Remitente",
        recipient: "Destinatario",
        newOwner: "Nuevo propietario",
        write: "Ejecutar",
        addToBlacklist: "Añadir a la lista negra",
        disableTransfers: "Desactivar transferencias",
        enableTransfers: "Activar transferencias",
        removeFromBlacklist: "Eliminar de la lista negra",
        account: "Dirección",
        buy: "Comprar",
        sell: "Vender",
        setpar: "Configurar dirección LP",
        setTeam: "Tasa de marketing",
        setpool: "Configurar proporción de liquidez",
        setDividends: "Configurar proporción de dividendos",
        setBurn: "Configurar proporción de quema",
        amountOu: "Cantidad",
        Dividendcoins: "Monedas para dividendos",
        setDividendcoins: "Dirección del token",
        setDividendswitch: "Interruptor de dividendos",
        enable: "Habilitar",
        Factory: "Contrato de fábrica",
        PancakeRouter: "Dirección del enrutador",
        Teamaddress: "Dirección del equipo",
        par: "Dirección del par",
        setFactory: "Configurar contrato de fábrica",
        setMinimum: "Mínimo de tenencia",
        setPancakeRouter: "Configurar dirección del enrutador",
        setTeamaddress: "Configurar dirección del equipo",
        updateBlacklist: "Actualizar lista negra",
        isBlacklisted: "Interruptor",
        updateTransfers: "Interruptor de transferencias",
        swapTokensAtAmount: "Cantidad mínima de venta",
        setswapTokensAtAmount: "Configurar cantidad mínima de venta",
        setRewardaddress: "Configurar dirección de recompensas",
        Rewardaddress: "Dirección",
        submit: "Enviar",
        enterContractAddress: "Ingrese la dirección del contrato",
        pleaseEnterValidContractAddress: "Ingrese una dirección de contrato válida",
        transactionSuccessful: "Transacción exitosa",
        contractNotOpenSourceOrInvalidAddressOrWrongChain: "Contrato no abierto, dirección de contrato inválida o cadena incorrecta"
      },
      dex: {
        swap: "Intercambiar",
        addliquidity: "Añadir liquidez",
        removeliquidity: "Eliminar liquidez",
        Balance: "Saldo:",
        ConnectWallet: "Conectar billetera",
        PleaseEnterAmount: "Por favor ingrese la cantidad a intercambiar",
        InsufficientLiquidity: "Liquidez insuficiente",
        InsufficientBalance: "Saldo insuficiente",
        Authorization: "Autorización",
        Transaction: "Transacción",
        PleaseEnterSwapAmount: "Por favor ingrese la cantidad a intercambiar",
        networkError: "Error de red",
        transactionSuccessful: "Transacción exitosa",
        PleaseEnterAddAmount: "Por favor ingrese la cantidad a añadir",
        PleaseEnterRemoveAmount: "Por favor ingrese la cantidad a eliminar",
        PairDoesNotExist: "El par no existe",
        PleaseEnterTokenAddressOrName: "Por favor ingrese la dirección o nombre del token",
        CommonTokens: "Tokens comunes",
        AddedByUser: "Añadido por el usuario",
        InvalidTokenAddressLength: "Longitud de dirección de token inválida",
        SlippageLimit: "Límite de deslizamiento",
        ExpertMode: "Modo experto",
        BypassConfirmationAndAllowHighSlippage: "Omita la confirmación y permita transacciones con alto deslizamiento. Use bajo su propio riesgo.",
        selecttoken: "Seleccionar token",
        settings: "Configuraciones"
      },
      mint: {
        CreateToken: "Crear Token",
        TokenManagement: "Gestión de Tokens",
        name: "Nombre:",
        TokenName: "Nombre del token",
        Symbol: "Símbolo:",
        TokenSymbol: "Símbolo del token",
        Quantity: "Cantidad:",
        TotalSupply: "Suministro total",
        Decimals: "Decimales:",
        TradeSwitch: "Interruptor de comercio:",
        Blacklist: "Lista negra:",
        HoldersDividend: "Dividendos para tenedores:",
        PoolDividend: "Dividendos de liquidez:",
        DividendContract: "Contrato de dividendos:",
        MinHold: "Mínima tenencia:",
        DividendToken: "Token de dividendos:",
        MarketingAddress: "Dirección de marketing:",
        buy: "Comprar:",
        pool: "Liquidez:",
        marketing: "Marketing:",
        dividend: "Dividendos:",
        Burn: "Quema:",
        sell: "Vender",
        SelectPoolContract: "Seleccione el contrato de liquidez",
        ConnectWallet: "Conectar billetera",
        address: "Dirección del contrato",
        deploySuccess: "Despliegue exitoso",
        copy: "Copiar",
        copySuccess: "Copiado exitosamente",
        networkNotSupported: "Esta red no es compatible por el momento",
        moreSettings: "Más configuraciones",
        enterContractAddress: "Por favor ingrese la dirección del contrato",
        manage: "Gestionar"
      },
      Mixezs: {
        deposit: "Depositar",
        Cheque: "Cheque",
        withdraw: "Retirar",
        token: "Token",
        selectToken: "Por favor seleccione un token",
        Amount: "Cantidad",
        selectAmount: "Por favor seleccione una cantidad",
        ConnectWallet: "Conectar billetera",
        Authorization: "Autorización",
        order: "Orden",
        selectDepositOrder: "Por favor seleccione una orden de depósito",
        recipient: "Receptor",
        enterRecipientAddress: "Por favor ingrese la dirección del receptor",
        generateVoucher: "Generar cheque",
        Proof: "Cheque",
        pleaseEnterProof: "Por favor ingrese el cheque",
        prooferror: "Cheque inválido o no eres el receptor, por favor ingresa nuevamente",
        withdrawsuccess: "Retiro exitoso",
        depositsuccess: "Depósito exitoso",
        withdrawproof: "Cheque de retiro",
        titleone: "El contrato de Mixezs ha sido",
        titletwo: "Auditado!",
        titlethree: "¡Ver informe de auditoría!",
        MixezsPrivacyTransfer: "Introducción a las transferencias privadas de Mixezs",
        MixezsIntro: "Mixezs es una herramienta de transferencia de blockchain enfocada en la protección de la privacidad, que combina técnicas modernas de criptografía para garantizar la privacidad de las transacciones y asegurar que cada transferencia sea imposible de rastrear. El sistema de Mixezs está compuesto por dos contratos inteligentes principales: el contrato de depósito (Mixer) y el contrato de verificación (Verifier).",
        CoreHighlights: "Aspectos destacados",
        SeamlessPrivacyProtection: "Protección de privacidad sin fisuras:",
        EffectiveTrackingPrevention: "A través del diseño de cantidades uniformes de depósito, Mixezs previene eficazmente la identificación y rastreo de las fuentes de los depósitos. Incluso si se pueden observar los registros de retiro, no se puede deducir la identidad del depositante.",
        DeepAnonymityVerification: "Verificación de anonimato profundo:",
        AdvancedSignatureVerification: "El sistema utiliza algoritmos avanzados de verificación de firmas y técnicas de generación de números aleatorios impredecibles, protegiendo completamente la privacidad del usuario. La identidad del usuario y los detalles de la transacción están cifrados y ofuscados, haciendo imposible obtener información específica sobre depósitos y transferencias.",
        HighlySecureVerificationLogic: "Lógica de verificación altamente segura:",
        VerifierContractDesign: "El diseño del algoritmo central del contrato Verifier no solo proporciona una capa adicional de seguridad al sistema, sino que también asegura la integridad del proceso de verificación. Con mecanismos de protección y verificación criptográfica incorporados, se previenen eficazmente ataques potenciales y explotación de vulnerabilidades.",
        AuditLevelSecurity: "Seguridad a nivel de auditoría:",
        ThirdPartyAudit: "Antes de su lanzamiento, todos los contratos han sido auditados rigurosamente por una tercera entidad de seguridad. Los usuarios pueden confiar completamente en la seguridad de sus fondos, con todas las transacciones realizadas sin comprometer la privacidad.",
        ContractFunctions: "Funciones del contrato",
        DepositContract: "Contrato de depósito (Mixer):",
        DepositMechanism: "Asegura que todos los usuarios depositen la misma cantidad, rompiendo la trayectoria original de los fondos mediante un mecanismo de mezcla, protegiendo al máximo la privacidad de la fuente de los fondos.",
        VerificationContract: "Contrato de verificación (Verifier):",
        SignatureVerification: "Realiza una verificación eficiente de firmas basada en mecanismos criptográficos de vanguardia, asegurando privacidad mientras previene ataques de repetición y falsificación de firmas.",
        MixezsAdvantages: "Ventajas de Mixezs",
        TopPrivacyProtection: "Protección de privacidad de primer nivel:",
        InnovativeDesign: "A través de un diseño innovador de contratos y técnicas criptográficas, Mixezs asegura completamente la privacidad del usuario, haciendo imposible el rastreo a través de datos en la cadena.",
        SecurityAndDecentralization: "Seguridad y descentralización:",
        RigorousAudit: "Los contratos inteligentes auditados rigurosamente operan en una blockchain descentralizada, garantizando la seguridad de las transacciones.",
        AntiAttackDesign: "Diseño antiataques:",
        CryptographicMechanisms: "Mediante mecanismos criptográficos de vanguardia, el sistema asegura la unicidad de cada transacción, evitando completamente ataques predictivos."
      },
      nav: {
        swap: 'intercambiar',
        mint: 'acuñar',
        Mixezs: 'Mezclador'
    }
}