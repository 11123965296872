export default {
    message:{
        approve: 'อนุญาต',
        renounceOwnership: 'สละสิทธิ์',
        transfer: 'โอน',
        transferFrom: 'โอนจาก...',
        transferOwnership: 'โอนกรรมสิทธิ์',
        amount: 'จำนวน',
        spender: 'ผู้ใช้จ่าย',
        sender: 'ผู้ส่ง',
        recipient: 'ผู้รับ',
        newOwner: 'เจ้าของใหม่',
        write: 'ดำเนินการ',
        addToBlacklist: 'เพิ่มลงในบัญชีดำ',
        disableTransfers: 'ปิดการโอน',
        enableTransfers: 'เปิดการโอน',
        removeFromBlacklist: 'ลบออกจากบัญชีดำ',
        account: 'ที่อยู่',
        buy: 'ซื้อ',
        sell: 'ขาย',
        setpar: 'ตั้งค่า LP Address',
        setTeam: 'อัตราค่าการตลาด',
        setpool: 'ตั้งค่าสัดส่วนเพิ่มพูล',
        setDividends: 'ตั้งค่าสัดส่วนปันผล',
        setBurn: 'ตั้งค่าสัดส่วนการเผา',
        _amountOu: 'จำนวน',
        _Dividendcoins: 'สกุลเงินปันผล',
        setDividendcoins: 'ที่อยู่โทเค็น',
        setDividendswitch: 'เปิด/ปิดการปันผล',
        enable: 'เปิด/ปิด',
        _Factory: 'สัญญาโรงงาน',
        _PancakeRouter: 'ที่อยู่ตัวเชื่อม',
        _Teamaddress: 'ที่อยู่ทีม',
        _par: 'ที่อยู่คู่',
        setFactory: 'ตั้งค่าสัญญาโรงงาน',
        setMinimum: 'ขั้นต่ำถือครอง',
        setPancakeRouter: 'ตั้งค่าตัวเชื่อม',
        setTeamaddress: 'ตั้งค่าที่อยู่ทีม',
        updateBlacklist: 'ตั้งค่าบัญชีดำ',
        _isBlacklisted: 'เปิด/ปิด',
        updateTransfers: 'เปิด/ปิดการโอน',
        swapTokensAtAmount: 'จำนวนการขายขั้นต่ำ',
        setswapTokensAtAmount: 'ตั้งค่าจำนวนการขายขั้นต่ำ',
        setRewardaddress: 'ตั้งค่าที่อยู่ปันผล',
        _Rewardaddress: 'ที่อยู่',
        submit: 'ส่ง',
        enterContractAddress: 'กรุณากรอกที่อยู่สัญญา',
        pleaseEnterValidContractAddress: 'กรุณากรอกที่อยู่สัญญาที่ถูกต้อง',
        transactionSuccessful: 'ธุรกรรมสำเร็จ',
        contractNotOpenSourceOrInvalidAddressOrWrongChain: 'สัญญายังไม่เปิดเผยหรือที่อยู่สัญญาผิดพลาดหรือสายผิด'
    },
    dex: {
		swap: "การแลกเปลี่ยน",
		addliquidity: "เพิ่มสภาพคล่อง",
		removeliquidity: "ลบสภาพคล่อง",
		Balance: "ยอดคงเหลือ:",
		ConnectWallet: "เชื่อมต่อกระเป๋าเงิน",
		PleaseEnterAmount: "กรุณากรอกจำนวนที่แลกเปลี่ยน",
		InsufficientLiquidity: "สภาพคล่องไม่เพียงพอ",
		InsufficientBalance: "ยอดคงเหลือไม่เพียงพอ",
		Authorization: "การอนุญาต",
		Transaction: "ธุรกรรม",
		PleaseEnterSwapAmount: "กรุณากรอกจำนวนที่แลกเปลี่ยน",
		networkError: "ข้อผิดพลาดของเครือข่าย",
		transactionSuccessful: "ธุรกรรมสำเร็จ",
		PleaseEnterAddAmount: "กรุณากรอกจำนวนที่เพิ่ม",
		PleaseEnterRemoveAmount: "กรุณากรอกจำนวนที่ลบ",
		PairDoesNotExist: "คู่การแลกเปลี่ยน不存在",
		PleaseEnterTokenAddressOrName: "กรุณากรอกที่อยู่หรือชื่อโทเค็น",
		CommonTokens: "โทเค็นทั่วไป",
		AddedByUser: "เพิ่มโดยผู้ใช้",
		InvalidTokenAddressLength: "ความยาวที่อยู่โทเค็นไม่ถูกต้อง",
		SlippageLimit: "ขีดจำกัดการลื่นไถล",
		ExpertMode: "โหมดผู้เชี่ยวชาญ",
		BypassConfirmationAndAllowHighSlippage: "ข้ามโหมดการยืนยันและอนุญาตให้ลื่นไถลสูง ใช้ความเสี่ยงตามที่คุณรับผิดชอบ",
		selecttoken: "เลือกโทเค็น",
		settings: "การตั้งค่า"
	},
	mint: {
		CreateToken: "สร้างโทเค็น",
		TokenManagement: "การจัดการโทเค็น",
		name: "ชื่อ:",
		TokenName: "ชื่อโทเค็น",
		Symbol: "สัญลักษณ์:",
		TokenSymbol: "สัญลักษณ์โทเค็น",
		Quantity: "จำนวน:",
		TotalSupply: "จำนวนทั้งหมดที่ออก",
		Decimals: "ทศนิยม:",
		TradeSwitch: "สวิตช์การซื้อขาย:",
		Blacklist: "บัญชีดำ:",
		HoldersDividend: "ปันผลผู้ถือ:",
		PoolDividend: "ปันผลพูล:",
		DividendContract: "สัญญาปันผล:",
		MinHold: "ขั้นต่ำการถือครอง:",
		DividendToken: "โทเค็นปันผล:",
		MarketingAddress: "ที่อยู่การตลาด:",
		buy: "ซื้อ:",
		pool: "สภาพคล่อง:",
		marketing: "การตลาด:",
		dividend: "ปันผล:",
		Burn: "การเผา:",
		sell: "ขาย",
		SelectPoolContract: "กรุณาเลือกสัญญาพูล",
		ConnectWallet: "เชื่อมต่อกระเป๋าเงิน",
		address: "ที่อยู่สัญญา",
		deploySuccess: "การติดตั้งสำเร็จ",
		copy: "คัดลอก",
		copySuccess: "คัดลอกสำเร็จ",
		networkNotSupported: "เครือข่ายนี้ยังไม่รองรับ",
		moreSettings: "การตั้งค่าเพิ่มเติม",
		enterContractAddress: "กรุณากรอกที่อยู่สัญญา",
		manage: "จัดการ"
	},
	Mixezs: {
		deposit: "ฝากเงิน",
		Cheque: "ใบรับรอง",
		withdraw: "ถอนเงิน",
		token: "โทเค็น",
		selectToken: "กรุณาเลือกโทเค็น",
		Amount: "จำนวน",
		selectAmount: "กรุณาเลือกจำนวน",
		ConnectWallet: "เชื่อมต่อกระเป๋าเงิน",
		Authorization: "การอนุญาต",
		order: "คำสั่ง",
		selectDepositOrder: "กรุณาเลือกคำสั่งฝากเงิน",
		recipient: "ผู้รับเงิน",
		enterRecipientAddress: "กรุณากรอกที่อยู่ของผู้รับ",
		generateVoucher: "สร้างใบรับรอง",
		Proof: "ใบรับรอง",
		pleaseEnterProof: "กรุณากรอกใบรับรอง",
		prooferror: "ใบรับรองผิดพลาดหรือคุณไม่ใช่ผู้รับ กรุณากรอกใหม่",
		withdrawsuccess: "การถอนเงินสำเร็จ",
		depositsuccess: "การฝากเงินสำเร็จ",
		withdrawproof: "ใบรับรองการถอน",
		titleone: "สัญญา Mixezs ได้รับการตรวจสอบแล้ว",
		titletwo: "การตรวจสอบ!",
		titlethree: "ดูรายงานการตรวจสอบ!",
		MixezsPrivacyTransfer: "แนะนำการโอนเงินส่วนตัวของ Mixezs",
		MixezsIntro: "Mixezs เป็นเครื่องมือการโอนเงินบล็อกเชนที่มุ่งเน้นการปกป้องความเป็นส่วนตัว ซึ่งรวมเทคโนโลยีการเข้ารหัสลับที่ทันสมัยเพื่อปกป้องความเป็นส่วนตัวของผู้ใช้และทำให้การโอนเงินแต่ละครั้งไม่สามารถติดตามได้ ระบบของ Mixezs ประกอบด้วยสองสัญญาหลัก: สัญญาฝาก (Mixer) และ สัญญาการตรวจสอบ (Verifier).",
		CoreHighlights: "จุดเด่นหลัก",
		SeamlessPrivacyProtection: "การปกป้องความเป็นส่วนตัวที่ไร้รอยต่อ:",
		EffectiveTrackingPrevention: "ด้วยการออกแบบจำนวนเงินฝากเดียวกัน Mixezs จึงป้องกันการระบุและติดตามแหล่งที่มาของเงินฝากอย่างมีประสิทธิภาพ แม้จะมีการตรวจสอบบันทึกการถอนเงินภายนอก ก็ไม่สามารถใช้ข้อมูลนี้ในการย้อนกลับเพื่อทราบตัวตนของผู้ฝากเงินได้.",
		DeepAnonymityVerification: "การตรวจสอบความเป็นนิรนามอย่างลึกซึ้ง:",
		AdvancedSignatureVerification: "ระบบใช้วิธีการตรวจสอบลายเซ็นขั้นสูงและรวมกับเทคโนโลยีการสร้างตัวเลขสุ่มที่ไม่สามารถคาดเดาได้ เพื่อปกป้องความเป็นส่วนตัวของผู้ใช้ได้อย่างครบถ้วน ตัวตนของผู้ใช้และรายละเอียดการทำธุรกรรมทั้งหมดถูกเข้ารหัสและทำให้ไม่สามารถอ่านได้ ทำให้ไม่มีใครสามารถรับข้อมูลการฝากเงินและการโอนเงินที่เฉพาะเจาะจงได้.",
		HighlySecureVerificationLogic: "ตรรกะการตรวจสอบที่ปลอดภัยสูง:",
		VerifierContractDesign: "การออกแบบอัลกอริธึมหลักของสัญญา Verifier ไม่เพียงแต่ให้แนวป้องกันความปลอดภัยเพิ่มเติม แต่ยังรับรองความสมบูรณ์ของกระบวนการตรวจสอบ ด้วยการตรวจสอบทางคณิตศาสตร์ที่ฝังตัวและกลไกการป้องกันที่มีประสิทธิภาพในการป้องกันการโจมตีและช่องโหว่ที่อาจเกิดขึ้น.",
		AuditLevelSecurity: "ความปลอดภัยระดับการตรวจสอบ:",
		ThirdPartyAudit: "ก่อนที่ระบบจะเปิดใช้งาน สัญญาทั้งหมดได้ผ่านการตรวจสอบจากหน่วยงานความปลอดภัยของบุคคลที่สามอย่างเข้มงวด ผู้ใช้สามารถมั่นใจได้อย่างเต็มที่ในความปลอดภัยของเงินทุนของตน และธุรกรรมใด ๆ จะดำเนินการในขอบเขตของความเป็นส่วนตัวที่ไม่รั่วไหล.",
		ContractFunctions: "ฟังก์ชันของสัญญา",
		DepositContract: "สัญญาฝาก (Mixer):",
		DepositMechanism: "รับรองว่าผู้ใช้ทั้งหมดจะฝากเงินในจำนวนเดียวกัน โดยใช้กลไกการผสมเงินทุนเพื่อทำลายเส้นทางการไหลของเงินทุนดั้งเดิม เพื่อปกป้องความเป็นส่วนตัวของแหล่งที่มาของเงินทุนให้ได้มากที่สุด.",
		VerificationContract: "สัญญาการตรวจสอบ (Verifier):",
		SignatureVerification: "ดำเนินการตรวจสอบลายเซ็นอย่างมีประสิทธิภาพ โดยใช้กลไกการเข้ารหัสลับขั้นสูง สัญญาทำให้แน่ใจว่ามีการป้องกันการโจมตีแบบเรียกซ้ำและการปลอมแปลงลายเซ็นในขณะที่รักษาความเป็นส่วนตัว.",
		MixezsAdvantages: "ข้อดีของ Mixezs",
		TopPrivacyProtection: "การปกป้องความเป็นส่วนตัวระดับสูง:",
		InnovativeDesign: "ด้วยการออกแบบสัญญาและเทคโนโลยีการเข้ารหัสลับที่เป็นนวัตกรรม Mixezs จึงรับประกันความเป็นส่วนตัวของผู้ใช้ และข้อมูลบนบล็อกเชนไม่สามารถย้อนกลับได้.",
		SecurityAndDecentralization: "ความปลอดภัยและการกระจายอำนาจ:",
		RigorousAudit: "สัญญาอัจฉริยะที่ผ่านการตรวจสอบอย่างเข้มงวดทำงานบนบล็อกเชนที่กระจายอำนาจ เพื่อรับประกันความปลอดภัยของการทำธุรกรรม.",
		AntiAttackDesign: "การออกแบบป้องกันการโจมตี:",
		CryptographicMechanisms: "ด้วยกลไกการเข้ารหัสลับขั้นสูง ระบบจึงรับประกันความเป็นเอกลักษณ์ของธุรกรรมแต่ละครั้ง และป้องกันการโจมตีที่คาดเดาได้."
	},
	nav: {
		swap: 'สวอป',
		mint: 'มิ้นต์',
		Mixezs: 'เครื่องผสม'
	}
}