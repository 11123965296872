import { createStore } from 'vuex'

export default createStore({
	state: {
		verify: false,
        orderlist:[],
		whethertolink:false,
		singhash:null,
		singtext:null

	},
	mutations: {
		setVerify(state, value) {
			state.verify = value
		},
		setOrderlist(state, value) {
			state.orderlist = value
		},
		Whethertolink(state, value) {
			state.whethertolink = value
		},
		setSinghash(state, value) {
			state.singhash = value
		},
		setSingtext(state, value) {
			state.singtext = value
		},

	},
	getters: {},
	actions: {},
	modules: {},
})