

export default {
	message: {
	approve:'approve',
	renounceOwnership:'renounceOwnership',
	transfer:'transfer',
	transferFrom:'transferFrom',
	transferOwnership:'transferOwnership',
	amount:'amount',
	spender:'spender',
	sender:'sender',
	recipient:"recipient",
	newOwner:'newOwner',
	write:"write",
	addToBlacklist:'addToBlacklist',
	disableTransfers:'disableTransfers',
	enableTransfers:'enableTransfers',
	removeFromBlacklist:'removeFromBlacklist',
	account:'account',
	buy:'buy',
	sell:'sell',
	setpar:'setpar',
	setTeam:'setTeam',
	setpool:"setpool",
	setDividends:'setpool',
	setBurn:'setBurn',
	_amountOu:'amountOu',
	_Dividendcoins:'_Dividendcoins',
	setDividendcoins:"setDividendcoins",
	setDividendswitch:'setDividendswitch',
	enable:'enable',
	_Factory:'_Factory',
	_PancakeRouter:'_PancakeRouter',
	_Teamaddress:'_Teamaddress',
	_par:'_par',
	setFactory:"setFactory",
	setMinimum:'setMinimum',
	setPancakeRouter:'setPancakeRouter',
	setTeamaddress:'setTeamaddress',
	updateBlacklist:'updateBlacklist',
	_isBlacklisted:'_isBlacklisted',
	updateTransfers:'transferEnabled',
	swapTokensAtAmount:'swapTokensAtAmount',
	setswapTokensAtAmount:'setswapTokensAtAmount',
	setRewardaddress:'setRewardaddress',
	_Rewardaddress:"_Rewardaddress",
	submit: 'Submit',
	enterContractAddress: 'Please enter the contract address',
	pleaseEnterValidContractAddress: 'Please enter a valid contract address',
	transactionSuccessful: "Transaction Successful",
	contractNotOpenSourceOrInvalidAddressOrWrongChain: 'Contract not open source, invalid address, or wrong chain'


	},
	dex: {
		swap: 'Swap',
		addliquidity: 'Add Liquidity',
		removeliquidity: 'Remove Liquidity',
		Balance: 'Balance:',
		ConnectWallet:'ConnectWallet',
		PleaseEnterAmount: 'Please enter the amount',
		InsufficientLiquidity: 'Insufficient liquidity',
		InsufficientBalance: 'Insufficient balance',
		Authorization: 'Approve',
		Transaction: 'Transaction',
		PleaseEnterSwapAmount: 'Please enter the swap amount',
		networkError: "Network Error",
		transactionSuccessful: "Transaction Successful",
        PleaseEnterAddAmount: "Please enter the amount to add",
		PleaseEnterRemoveAmount: "Please enter the amount to remove",
		PairDoesNotExist: "Pair does not exist",
		PleaseEnterTokenAddressOrName: "Please enter the token address or name",
		CommonTokens: "Common tokens",
		AddedByUser: "Added by user",
		InvalidTokenAddressLength: "Token address length is incorrect",
		SlippageLimit: "Slippage limit",
		ExpertMode: "Expert mode",
		BypassConfirmationAndAllowHighSlippage: "Bypass confirmation mode and allow high slippage trades. Use at your own risk.",
		selecttoken: 'Select token',
		settings: 'Settings'


	},
	mint:{
        CreateToken: "Create token",
		TokenManagement: "Token management",
		name:"name:",
		TokenName: "Token name",
		Symbol:"symbol:",
		TokenSymbol: "Token symbol",
		Quantity: "Quantity:",
		TotalSupply: "Total supply",
		Decimals: "Decimals:",
		TradeSwitch: "Trade switch:",
        Blacklist: "Blacklist:",
		HoldersDividend: "Dividends:",
		PoolDividend: "Pooldividend:",
		DividendContract: "poolcontract:",
		MinHold: "Min hold:",
		DividendToken: "DividendToken:",
		MarketingAddress: "MarketingAddr:",
		buy:"buy:",
		pool:"pool:",
		marketing:"marketing:",
		dividend:"dividend:",
		Burn: "Burn:",
		sell:"sell:",
		SelectPoolContract: "Select pool contract",
		ConnectWallet:'ConnectWallet',
		address:'address',
		deploySuccess: 'deployment successful',
		copy: 'Copy',
		copySuccess: 'Copy successful',
		networkNotSupported: 'Network not supported',
		moreSettings: 'More Settings',
		enterContractAddress: 'Please enter the contract address',
		manage: 'Manage',
	},
	Mixezs:{
		deposit:"Deposit",
		Cheque:'Proof',
		withdraw:'Withdraw',
		token:'Token',
		selectToken: 'Select token',
		Amount:"Amount",
		selectAmount: 'Select amount',
		ConnectWallet:'ConnectWallet',
		Authorization: 'Approve',
		order: 'Order',
		selectDepositOrder: 'Select deposit order',
		recipient: 'Recipient',
		enterRecipientAddress: 'Enter recipient address',
		generateVoucher: 'Generate Proof',
		Proof:'Proof',
		pleaseEnterProof: 'Please enter proof',
		prooferror:'Invalid proof or you are not the recipient, please re-enter.',
		withdrawsuccess: 'Withdrawal successful',
		depositsuccess: 'Deposit successful',
		withdrawproof: 'Withdrawal proof',
		titleone:'Mixezs contract has passed',
		titletwo:'auditing!',
		titlethree:"View the audit report!",
        MixezsPrivacyTransfer: "Mixezs Privacy Transfer Introduction",
		MixezsIntro: "Mixezs is a blockchain transfer tool focused on privacy protection, combining modern cryptographic techniques to ensure user transaction privacy and ensure that each transfer is untraceable by external parties. The Mixezs system consists of two core smart contracts: the Deposit Contract (Mixer) and the Verification Contract (Verifier).",
        CoreHighlights: "Core Highlights",
        SeamlessPrivacyProtection: "Seamless Privacy Protection:",
        EffectiveTrackingPrevention: "Through the unified deposit amount design, Mixezs effectively prevents external identification and tracking of deposit sources. Even if external parties can observe withdrawal records, they cannot reverse-engineer the identity of the depositors.",
        DeepAnonymityVerification: "Deep Anonymity Verification:",
        AdvancedSignatureVerification: "The system uses advanced signature verification algorithms combined with unpredictable random number generation techniques to fully protect user privacy. User identities and transaction details are encrypted and obfuscated, making it impossible for anyone to access specific deposit and transaction information.",
        HighlySecureVerificationLogic: "Highly Secure Verification Logic:",
        VerifierContractDesign: "The core algorithm design of the Verifier contract not only provides an additional security layer but also ensures the integrity of the verification process. With built-in cryptographic verification and protection mechanisms, potential attacks and vulnerabilities are effectively prevented.",
        AuditLevelSecurity: "Audit-Level Security:",
        ThirdPartyAudit: "Before going live, all contracts were thoroughly audited by third-party security agencies. Users can completely trust the security of their funds, as every transaction is carried out without disclosing privacy.",
        ContractFunctions: "Contract Functions",
        DepositContract: "Deposit Contract (Mixer):",
        DepositMechanism: "Ensures all users deposit the same amount, breaking the original flow of funds through a mixing mechanism, thereby maximizing the protection of fund source privacy.",
        VerificationContract: "Verification Contract (Verifier):",
        SignatureVerification: "Implements efficient signature verification. Based on cutting-edge cryptographic mechanisms, the contract ensures privacy while preventing replay attacks and signature forgery.",
        MixezsAdvantages: "Mixezs Advantages",
        TopPrivacyProtection: "Top-Level Privacy Protection:",
        InnovativeDesign: "Through innovative contract design and cryptographic technology, Mixezs guarantees user privacy completely, making it impossible for external parties to trace through on-chain data.",
        SecurityAndDecentralization: "Security and Decentralization:",
        RigorousAudit: "Audited smart contracts running on a decentralized blockchain ensure transaction security.",
        AntiAttackDesign: "Anti-Attack Design:",
        CryptographicMechanisms: "Through cutting-edge cryptographic mechanisms, the system ensures the uniqueness of each transaction and thoroughly prevents predictive attacks."
	},
	nav: {
		swap: 'swap',
		mint: 'mint',
		Mixezs: 'Mixer'
	}


}
