import { createI18n } from 'vue-i18n'
import zh from './zh'
import en from './en'
import fr from './fr'
import kr from './kr'
import th from './th'
import tw from './tw'
import es from './es'
const i18n = createI18n({
  locale: 'en',
  messages: {
    zh,
    en,
    fr,
    kr,
    th,
    tw,
    es
  }
})
export default i18n