export default {
    message: {
        approve: "권한 부여",
        renounceOwnership: "소유권 포기",
        transfer: "전송",
        transferFrom: "에서 전송",
        transferOwnership: "소유권 이전",
        amount: "수량",
        spender: "지출자",
        sender: "발신자",
        recipient: "수신자",
        newOwner: "새 소유자",
        write: "실행",
        addToBlacklist: "블랙리스트 추가",
        disableTransfers: "전송 비활성화",
        enableTransfers: "전송 활성화",
        removeFromBlacklist: "블랙리스트 제거",
        account: "주소",
        buy: "구매",
        sell: "판매",
        setpar: "LP 주소 설정",
        setTeam: "마케팅 비율 설정",
        setpool: "풀 비율 설정",
        setDividends: "배당 비율 설정",
        setBurn: "소각 비율 설정",
        amountOu: "수량",
        Dividendcoins: "보유한 토큰으로 배당",
        setDividendcoins: "토큰 주소",
        setDividendswitch: "배당 스위치 설정",
        enable: "스위치",
        Factory: "팩토리 계약",
        PancakeRouter: "라우터 주소",
        Teamaddress: "팀 주소",
        par: "거래 쌍 주소",
        setFactory: "팩토리 계약 설정",
        setMinimum: "최소 보유량 설정",
        setPancakeRouter: "라우터 주소 설정",
        setTeamaddress: "팀 주소 설정",
        updateBlacklist: "블랙리스트 설정",
        isBlacklisted: "스위치",
        updateTransfers: "전송 스위치",
        swapTokensAtAmount: "최소 판매 금액",
        setswapTokensAtAmount: "최소 판매 금액 설정",
        setRewardaddress: "배당 주소 설정",
        Rewardaddress: "주소",
        submit: "제출",
        enterContractAddress: "계약 주소를 입력하세요",
        pleaseEnterValidContractAddress: "유효한 계약 주소를 입력하세요",
        transactionSuccessful: "거래 성공",
        contractNotOpenSourceOrInvalidAddressOrWrongChain: "계약이 오픈소스가 아니거나 주소가 잘못되었거나 체인이 잘못되었습니다"
      },
      dex: {
        swap: "거래",
        addliquidity: "유동성 추가",
        removeliquidity: "유동성 제거",
        Balance: "잔액:",
        ConnectWallet: "지갑 연결",
        PleaseEnterAmount: "교환할 수량을 입력하세요",
        InsufficientLiquidity: "유동성 부족",
        InsufficientBalance: "잔액 부족",
        Authorization: "권한 부여",
        Transaction: "거래",
        PleaseEnterSwapAmount: "교환할 수량을 입력하세요",
        networkError: "네트워크 오류",
        transactionSuccessful: "거래 성공",
        PleaseEnterAddAmount: "추가할 수량을 입력하세요",
        PleaseEnterRemoveAmount: "제거할 수량을 입력하세요",
        PairDoesNotExist: "거래 쌍이 존재하지 않음",
        PleaseEnterTokenAddressOrName: "토큰 주소 또는 이름을 입력하세요",
        CommonTokens: "자주 사용하는 토큰",
        AddedByUser: "사용자가 추가함",
        InvalidTokenAddressLength: "토큰 주소 길이 오류",
        SlippageLimit: "슬ippage 한도",
        ExpertMode: "전문가 모드",
        BypassConfirmationAndAllowHighSlippage: "확인 모드를 우회하고 높은 슬ippage 거래를 허용합니다. 사용에 주의하세요.",
        selecttoken: "토큰 선택",
        settings: "설정"
      },
      mint: {
        CreateToken: "토큰 생성",
        TokenManagement: "토큰 관리",
        name: "이름:",
        TokenName: "토큰 이름",
        Symbol: "심볼:",
        TokenSymbol: "토큰 심볼",
        Quantity: "수량:",
        TotalSupply: "발행 수량",
        Decimals: "소수점:",
        TradeSwitch: "거래 스위치:",
        Blacklist: "블랙리스트:",
        HoldersDividend: "보유자 배당:",
        PoolDividend: "풀 배당:",
        DividendContract: "배당 계약:",
        MinHold: "최소 보유량:",
        DividendToken: "배당 토큰:",
        MarketingAddress: "마케팅 주소:",
        buy: "구매:",
        pool: "유동성:",
        marketing: "마케팅:",
        dividend: "배당:",
        Burn: "소각:",
        sell: "판매",
        SelectPoolContract: "풀 계약 선택",
        ConnectWallet: "지갑 연결",
        address: "계약 주소",
        deploySuccess: "배포 성공",
        copy: "복사",
        copySuccess: "복사 성공",
        networkNotSupported: "이 네트워크는 지원되지 않습니다",
        moreSettings: "추가 설정",
        enterContractAddress: "계약 주소를 입력하세요",
        manage: "관리"
      },
      Mixezs: {
        deposit: "입금",
        Cheque: "증명서",
        withdraw: "출금",
        token: "토큰",
        selectToken: "토큰 선택",
        Amount: "수량",
        selectAmount: "수량 선택",
        ConnectWallet: "지갑 연결",
        Authorization: "권한 부여",
        order: "주문",
        selectDepositOrder: "입금 주문 선택",
        recipient: "출금자",
        enterRecipientAddress: "수신자 주소를 입력하세요",
        generateVoucher: "증명서 생성",
        Proof: "증명서",
        pleaseEnterProof: "증명서를 입력하세요",
        prooferror: "증명서 오류 또는 수신자가 아닙니다. 다시 입력하세요.",
        withdrawsuccess: "출금 성공",
        depositsuccess: "입금 성공",
        withdrawproof: "출금 증명서",
        titleone: "Mixezs 계약이",
        titletwo: "감사를 통과했습니다!",
        titlethree: "감사 보고서 보기!",
        MixezsPrivacyTransfer: "Mixezs 프라이버시 전송 소개",
        MixezsIntro: "Mixezs는 최신 암호화 기술을 결합하여 사용자 거래 프라이버시를 보호하는 블록체인 전송 도구입니다. Mixezs 시스템은 두 개의 핵심 스마트 계약으로 구성됩니다: 입금 계약 (Mixer) 및 검증 계약 (Verifier).",
        CoreHighlights: "핵심 특징",
        SeamlessPrivacyProtection: "무결한 프라이버시 보호:",
        EffectiveTrackingPrevention: "통일된 입금 금액 설계를 통해 Mixezs는 외부에서 입금 출처를 식별하고 추적하는 것을 효과적으로 방지합니다. 외부에서 출금 기록을 관찰하더라도 자금의 입금자를 역추적할 수 없습니다.",
        DeepAnonymityVerification: "심층 익명성 검증:",
        AdvancedSignatureVerification: "시스템은 고급 서명 검증 알고리즘과 예측할 수 없는 난수 생성 기술을 결합하여 사용자의 프라이버시를 전방위적으로 보호합니다. 사용자 신원과 거래 세부 사항은 암호화 및 혼합 처리되어, 누구도 구체적인 입금 및 전송 정보를 알 수 없습니다.",
        HighlySecureVerificationLogic: "고도로 안전한 검증 로직:",
        VerifierContractDesign: "Verifier 계약의 핵심 알고리즘 설계는 시스템에 추가적인 보안 방어선을 제공하며, 검증 과정의 무결성을 보장합니다. 내장된 암호학적 검증 및 방어 메커니즘을 통해 잠재적인 공격 및 취약점 활용을 효과적으로 차단합니다.",
        AuditLevelSecurity: "감사 수준의 보안성:",
        ThirdPartyAudit: "출시 전 모든 계약은 제3자 보안 기관의 철저한 감사를 받았습니다. 사용자는 자금의 안전성에 대해 완전히 신뢰할 수 있으며, 모든 거래는 프라이버시가 유출되지 않는 조건에서 진행됩니다.",
        ContractFunctions: "계약 기능",
        DepositContract: "입금 계약 (Mixer):",
        DepositMechanism: "모든 사용자가 동일한 금액으로 입금하도록 하여 자금 혼합 메커니즘을 통해 원래 자금의 흐름 궤적을 깨뜨리고 자금 출처의 프라이버시를 최대한 보호합니다.",
        VerificationContract: "검증 계약 (Verifier):",
        SignatureVerification: "효율적인 서명 검증을 구현하며, 첨단 암호화 메커니즘을 기반으로 계약은 프라이버시를 보장하면서도 재전송 공격과 서명 위조를 방지합니다.",
        MixezsAdvantages: "Mixezs의 장점",
        TopPrivacyProtection: "최고 수준의 프라이버시 보호:",
        InnovativeDesign: "혁신적인 계약 설계와 암호화 기술을 통해 Mixezs는 사용자 프라이버시를 철저히 보장하며, 외부에서는 체인 상 데이터를 통해 추적할 수 없습니다.",
        SecurityAndDecentralization: "보안성 및 탈중앙화:",
        RigorousAudit: "엄격한 감사를 받은 스마트 계약이 탈중앙화된 블록체인에서 실행되며, 거래의 안전성을 보장합니다.",
        AntiAttackDesign: "공격 방어 설계:",
        CryptographicMechanisms: "최첨단 암호화 메커니즘을 통해 시스템은 각 거래의 유일성을 보장하고, 예측 공격을 완전히 방지합니다."
      },
      nav: {
        swap: '스왑',
        mint: '발행',
        Mixezs: '믹서기'
     }

}