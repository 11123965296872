export default {
	message: {
	  approve: "Autoriser",
	  renounceOwnership: "Renoncer à la propriété",
	  transfer: "Transfert",
	  transferFrom: "Transférer de...",
	  transferOwnership: "Transférer la propriété",
	  amount: "Montant",
	  spender: "Dépenseur",
	  sender: "Expéditeur",
	  recipient: "Destinataire",
	  newOwner: "Nouveau propriétaire",
	  write: "Exécuter",
	  addToBlacklist: "Ajouter à la liste noire",
	  disableTransfers: "Désactiver les transferts",
	  enableTransfers: "Activer les transferts",
	  removeFromBlacklist: "Retirer de la liste noire",
	  account: "Adresse",
	  buy: "Acheter",
	  sell: "Vendre",
	  setpar: "Définir l'adresse LP",
	  setTeam: "Taux de marketing",
	  setpool: "Définir le pourcentage de pool",
	  setDividends: "Définir le pourcentage de dividende",
	  setBurn: "Définir le pourcentage de brûlage",
	  amountOu: "Montant",
	  Dividendcoins: "Monnaies de dividende",
	  setDividendcoins: "Adresse du jeton",
	  setDividendswitch: "Interrupteur de dividende",
	  enable: "Activer",
	  Factory: "Contrat de fabrique",
	  PancakeRouter: "Adresse du routeur",
	  Teamaddress: "Adresse de l'équipe",
	  par: "Adresse de la paire",
	  setFactory: "Définir le contrat de fabrique",
	  setMinimum: "Minimum de détention",
	  setPancakeRouter: "Définir l'adresse du routeur",
	  setTeamaddress: "Définir l'adresse de l'équipe",
	  updateBlacklist: "Définir la liste noire",
	  isBlacklisted: "Activer",
	  updateTransfers: "Interrupteur de transfert",
	  swapTokensAtAmount: "Montant minimum de vente",
	  setswapTokensAtAmount: "Définir le montant minimum de vente",
	  setRewardaddress: "Définir l'adresse de récompense",
	  Rewardaddress: "Adresse",
	  submit: "Soumettre",
	  enterContractAddress: "Veuillez entrer l'adresse du contrat",
	  pleaseEnterValidContractAddress: "Veuillez entrer une adresse de contrat valide",
	  transactionSuccessful: "Transaction réussie",
	  contractNotOpenSourceOrInvalidAddressOrWrongChain: "Le contrat n'est pas open source, ou l'adresse du contrat est incorrecte, ou la chaîne est incorrecte"
	},
	dex: {
	  swap: "Échange",
	  addliquidity: "Ajouter de la liquidité",
	  removeliquidity: "Retirer de la liquidité",
	  Balance: "Solde:",
	  ConnectWallet: "Connecter le portefeuille",
	  PleaseEnterAmount: "Veuillez entrer le montant à échanger",
	  InsufficientLiquidity: "Liquidité insuffisante",
	  InsufficientBalance: "Solde insuffisant",
	  Authorization: "Autorisation",
	  Transaction: "Transaction",
	  PleaseEnterSwapAmount: "Veuillez entrer le montant de l'échange",
	  networkError: "Erreur de réseau",
	  transactionSuccessful: "Transaction réussie",
	  PleaseEnterAddAmount: "Veuillez entrer le montant à ajouter",
	  PleaseEnterRemoveAmount: "Veuillez entrer le montant à retirer",
	  PairDoesNotExist: "La paire n'existe pas",
	  PleaseEnterTokenAddressOrName: "Veuillez entrer l'adresse ou le nom du jeton",
	  CommonTokens: "Jetons courants",
	  AddedByUser: "Ajouté par l'utilisateur",
	  InvalidTokenAddressLength: "Longueur de l'adresse du jeton incorrecte",
	  SlippageLimit: "Limite de slippage",
	  ExpertMode: "Mode expert",
	  BypassConfirmationAndAllowHighSlippage: "Contourner le mode de confirmation et permettre un slippage élevé. Utilisez à vos risques et périls.",
	  selecttoken: "Sélectionner un jeton",
	  settings: "Paramètres"
	},
	mint: {
	  CreateToken: "Créer un jeton",
	  TokenManagement: "Gestion des jetons",
	  name: "Nom:",
	  TokenName: "Nom du jeton",
	  Symbol: "Symbole:",
	  TokenSymbol: "Symbole du jeton",
	  Quantity: "Quantité:",
	  TotalSupply: "Quantité totale",
	  Decimals: "Décimales:",
	  TradeSwitch: "Interrupteur de commerce:",
	  Blacklist: "Liste noire:",
	  HoldersDividend: "Dividende des détenteurs:",
	  PoolDividend: "Dividende de pool:",
	  DividendContract: "Contrat de dividende:",
	  MinHold: "Détention minimale:",
	  DividendToken: "Jeton de dividende:",
	  MarketingAddress: "Adresse marketing:",
	  buy: "Acheter:",
	  pool: "Liquidité:",
	  marketing: "Marketing:",
	  dividend: "Dividende:",
	  Burn: "Brûlage:",
	  sell: "Vendre",
	  SelectPoolContract: "Veuillez sélectionner le contrat de pool",
	  ConnectWallet: "Connecter le portefeuille",
	  address: "Adresse du contrat",
	  deploySuccess: "Déploiement réussi",
	  copy: "Copier",
	  copySuccess: "Copie réussie",
	  networkNotSupported: "Réseau non supporté",
	  moreSettings: "Plus de paramètres",
	  enterContractAddress: "Veuillez entrer l'adresse du contrat",
	  manage: "Gérer"
	},
	Mixezs: {
	  deposit: "Dépôt",
	  Cheque: "Chèque",
	  withdraw: "Retrait",
	  token: "Jeton",
	  selectToken: "Veuillez sélectionner un jeton",
	  Amount: "Montant",
	  selectAmount: "Veuillez sélectionner un montant",
	  ConnectWallet: "Connecter le portefeuille",
	  Authorization: "Autorisation",
	  order: "Commande",
	  selectDepositOrder: "Veuillez sélectionner une commande de dépôt",
	  recipient: "Destinataire",
	  enterRecipientAddress: "Veuillez entrer l'adresse du destinataire",
	  generateVoucher: "Générer un chèque",
	  Proof: "Preuve",
	  pleaseEnterProof: "Veuillez entrer la preuve",
	  prooferror: "Preuve incorrecte ou vous n'êtes pas le destinataire, veuillez entrer à nouveau",
	  withdrawsuccess: "Retrait réussi",
	  depositsuccess: "Dépôt réussi",
	  withdrawproof: "Preuve de retrait",
	  titleone: "Le contrat Mixezs a été audité",
	  titletwo: "avec succès !",
	  titlethree: "Voir le rapport d'audit !",
	  MixezsPrivacyTransfer: "Présentation de Mixezs Privacy Transfer",
	  MixezsIntro: "Mixezs est un outil de transfert blockchain axé sur la protection de la vie privée, combinant des techniques cryptographiques modernes pour protéger la confidentialité des transactions des utilisateurs et garantir que chaque transfert est introuvable. Le système Mixezs est composé de deux contrats intelligents principaux : le contrat de dépôt (Mixer) et le contrat de vérification (Verifier).",
	  CoreHighlights: "Points forts",
	  SeamlessPrivacyProtection: "Protection de la vie privée fluide :",
	  EffectiveTrackingPrevention: "Grâce à un montant de dépôt uniforme, Mixezs empêche efficacement l'identification et le suivi de la source des dépôts. Même si des enregistrements de retrait sont observés, il est impossible de déduire l'identité des déposants.",
	  DeepAnonymityVerification: "Vérification approfondie de l'anonymat :",
	  AdvancedSignatureVerification: "Le système utilise des algorithmes de vérification de signature avancés, combinés à une génération de nombres aléatoires imprévisibles, pour protéger complètement la vie privée des utilisateurs. L'identité des utilisateurs et les détails des transactions sont cryptés et obscurcis, rendant impossible l'accès aux informations spécifiques de dépôt et de transfert.",
	  HighlySecureVerificationLogic: "Logique de vérification hautement sécurisée :",
	  VerifierContractDesign: "La conception des algorithmes centraux du contrat Verifier offre une ligne de défense supplémentaire pour le système et garantit l'intégrité du processus de vérification. Grâce à des vérifications cryptographiques intégrées et à des mécanismes de protection, elle empêche efficacement les attaques potentielles et les exploits.",
	  AuditLevelSecurity: "Sécurité au niveau de l'audit :",
	  ThirdPartyAudit: "Avant le lancement, tous les contrats ont été soumis à un audit rigoureux par une agence de sécurité tierce. Les utilisateurs peuvent avoir confiance dans la sécurité de leurs fonds, toutes les transactions étant effectuées sans divulgation de la vie privée.",
	  ContractFunctions: "Fonctions des contrats",
	  DepositContract: "Contrat de dépôt (Mixer) :",
	  DepositMechanism: "Assure que tous les utilisateurs effectuent des dépôts du même montant et utilise un mécanisme de mélange de fonds pour briser les traces de circulation des fonds d'origine, protégeant ainsi au maximum la confidentialité de la source des fonds.",
	  VerificationContract: "Contrat de vérification (Verifier) :",
	  SignatureVerification: "Implémente une vérification de signature efficace basée sur des mécanismes cryptographiques avancés, garantissant la confidentialité tout en empêchant les attaques de relecture et les falsifications de signature.",
      MixezsAdvantages: "Les avantages de Mixezs",
      TopPrivacyProtection: "Protection de la vie privée de premier ordre :",
      InnovativeDesign: "Grâce à une conception innovante des contrats et des technologies cryptographiques, Mixezs garantit la confidentialité des utilisateurs, rendant impossible la traçabilité des données sur la chaîne.",
      SecurityAndDecentralization: "Sécurité et décentralisation :",
      RigorousAudit: "Les contrats intelligents, rigoureusement audités, fonctionnent sur une blockchain décentralisée, garantissant la sécurité des transactions.",
      AntiAttackDesign: "Conception anti-attaque :",
      CryptographicMechanisms: "Grâce à des mécanismes cryptographiques de pointe, le système assure l'unicité de chaque transaction, empêchant complètement les attaques prédictives."
    },
	nav: {
		swap: 'échanger',
		mint: 'frapper',
		Mixezs: 'Mélangeur'
	}
};
